import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';

import { ContentBox, ContentBoxHead, ContentBoxBody } from 'sg-ui-components';

const PasswordExpiring = ({ user }) => {
    return (
        <div className='login-container'>
            <div className='my-5' />
            <ContentBox variant='theme-blue'>
                <ContentBoxHead>UPDATE PASSWORD</ContentBoxHead>
                <ContentBoxBody>
                    <div className='text-center'>
                        <p>Your password is going to expire in {user.expiration_days} days. Please update it in your profile.</p>
                    </div>
                    <a className='btn theme-btn theme-secondary btn-block' href='/my-profile'>
                        Update Now
                    </a>
                    <a className='btn theme-btn theme-outline btn-block' href='/home'>
                        Remind me Later
                    </a>
                </ContentBoxBody>
            </ContentBox>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordExpiring);
