import React, { useState, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import { DisplayContent, BoxedContent, useIsScrollComplete } from 'sg-ui-components';

const AcceptTermsOfUse = ({ user, actions, termsContent, telescript }) => {
    const [hasDeclined, setHasDeclined] = useState(false);
    const refTermsOfUseContent = useRef(null);
    const { isScrollComplete } = useIsScrollComplete({ ref: refTermsOfUseContent });

    //! For internal testing purposes: const testing = false;
    const testing = false;

    const acceptBlockIsVisible = user?.loggedIn && (testing || user?.player?.actions?.includes('tos'));

    let myLocation = typeof window !== 'undefined' ? window.location.protocol + '//' + window.location.host : '';

    const handleAccept = async () => {
        await actions.userActions.actionComplete({ action_name: 'tos' });
        window.location.href = myLocation + '/home';
    };

    const handleLogout = () => {
        window.location.href = myLocation + '/logout';
    };

    return (
        <React.Fragment key='accept-terms-of-use-section'>
            <div
                id='terms-of-use-content'
                ref={refTermsOfUseContent}
                dangerouslySetInnerHTML={{ __html: termsContent ?? telescript?.noContent ?? 'No content found' }}
            />

            <DisplayContent note='Accept Terms of Use section' isVisible={acceptBlockIsVisible}>
                <div className='accept-terms-of-use-section'>
                    <button type='submit' name='submit' className='decline-tos-btn' onClick={() => setHasDeclined(true)}>
                        {telescript?.btnDecline ?? 'Decline'}
                    </button>

                    <button type='submit' name='submit' className='accept-tos-btn' disabled={!isScrollComplete} onClick={() => handleAccept()}>
                        {telescript?.btnAcceptTermsOfUse ?? 'Accept'}
                    </button>
                </div>
            </DisplayContent>

            <Modal show={hasDeclined} size='lg' backdrop='static' dialogClassName='decline-terms-of-use-modal' autoFocus={true} keyboard={false} centered>
                <BoxedContent note='Decline Terms Of Use modal' isActive={true} hasCollapser={false} title={telescript?.declineModalTitle ?? 'Declined'}>
                    <div className='modal-content-body'>
                        <p>{telescript?.declineModalMessage ?? 'To continue using your account, you must accept the Terms of Service.'}</p>

                        <button type='submit' name='submit' className='decline-tos-btn' onClick={() => handleLogout()}>
                            {telescript?.declineModalLogoutButton ?? 'Log Out'}
                        </button>

                        <button type='submit' name='submit' className='accept-tos-btn' onClick={() => setHasDeclined(false)}>
                            {telescript?.declineModalAcceptButton ?? 'Terms of Service'}
                        </button>
                    </div>
                </BoxedContent>
            </Modal>
        </React.Fragment>
    );
};

export default AcceptTermsOfUse;
