import React from 'react';
import { connect } from 'react-redux';
import { BoxedContent, CMSContent, hasCMSContent, DisplayContent } from 'sg-ui-components';

import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';

const PrivacyPage = ({ user, config, cmsSourceFirebase, actions, match }) => {
    return (
        <Layout {...{ user, config, cmsSourceFirebase, actions, match }}>
            <DisplayContent note='Privacy Policy section' isVisible={hasCMSContent('data.helpAndFaqs.privacyPolicy.contentHTML')}>
                <BoxedContent
                    note='Privacy section'
                    hash='privacy'
                    isActive={true}
                    hasCollapser={false}
                    isVisible={hasCMSContent('data.legalAndPolicyItems.privacyPolicy.contentHeaderText')}
                    title={
                        <CMSContent
                            localStorageObject='webContent'
                            contentPath='data.legalAndPolicyItems.privacyPolicy.contentHeaderText'
                            cmsSourceFirebase={cmsSourceFirebase}
                        />
                    }>
                    <CMSContent
                        localStorageObject='webContent'
                        contentPath='data.legalAndPolicyItems.privacyPolicy.contentHTML'
                        cmsSourceFirebase={cmsSourceFirebase}
                    />
                </BoxedContent>
            </DisplayContent>
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPage);
