import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { isBrowser, getCookieByName, isApp } from 'sg-ui-components';

import { mapStateToProps, mapDispatchToProps } from '../Store';
import LoadingIndicator from '../Components/LoadingIndicator';

/********************************************************************************************************
 * Component: LoggedInOnlyPages
 * Purpose:   Handled redirection policy for various actions on the
 *            users profile.   Each action should force the user
 *            to a specific page to complete the action required
 *
 * Props: -   children -  reference to the page the user wanted to navigate to
 *            loading  -  reference to the loading store
 *            user     -  reference to the user store
 *            config   -  reference to the config store
 *            currentSection -  Identifies the current section of the page we are on (optional)
 *            disableRedirect -  Tells the component to NOT open the ActionNotification modal for the action page - for
 *                              instance if we are already on that action to prevent modal open loop.  (optional)
 *
 * APIs used:  none
 */

const LoggedInOnlyPages = ({ children, loading, user, config }) => {
    const [hasMounted, setHasMounted] = useState(false);

    useEffect(() => {
        setHasMounted(true);
    }, [loading, hasMounted, user]);

    let myLocation = isBrowser() ? window.location.protocol + '//' + window.location.host + '/login-pta' : '';
    // remove any trailing slashes
    myLocation = myLocation.replace(/\/$/, '');

    const defaultLoginUrl = config?.site?.defaultLoginLink ?? '/login';

    if (!hasMounted || loading.actions['getPlayerData'] || loading.actions['getConfig'] || loading.actions['login']) {
        return <LoadingIndicator priority='normal' />;
    } else {
        if (user.loggedIn) {
            return children;
        } else {
            // It's possible that the user could be logged in but the store isn't up to date
            // yet -  so check the AuthToken cookie, if its there, then the user should be soon
            // logged in - if not go to login screen.

            if (!getCookieByName('AuthToken')) {
                if (isApp()) {
                    let myLocation = typeof window !== 'undefined' ? window.location.protocol + '//' + window.location.host + window.location.pathname : '';
                    // remove any trailing slashes
                    myLocation = myLocation.replace(/\/$/, '');

                    //? For debug: console.log('🚀 triggerAppLogin from: ' + myLocation);
                    window.parent.postMessage(
                        {
                            message: 'openLogin',
                            redirect: myLocation,
                        },
                        '*'
                    );
                } else {
                    const loginUrl = config.config?.login_host_uri ? `${config.config.login_host_uri}?destURL=${myLocation}` : defaultLoginUrl;
                    window.location.href = loginUrl;
                }
            }

            return <LoadingIndicator priority='normal' />;
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LoggedInOnlyPages);
