import React from 'react';
import { connect } from 'react-redux';
import { Container, Navbar } from 'react-bootstrap';
import { getCMSObject, CMSContent } from 'sg-ui-components';
import { mapStateToProps, mapDispatchToProps } from '../../../Store';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/pro-solid-svg-icons';
import Menu from '../../Menu.js';

library.add(faBars, faXmark);
dom.watch();

const Header = () => {
    return (
        <div className='page-header'>
            <header>
                <Container className='d-md-block d-none'>
                    <div className='row header border-bottom'>
                        <ToolbarNavLeft />
                        <ToolbarNavRight />
                    </div>
                </Container>
                <LotteryNav />
                <MobileNav />
                <PrimaryNav />
            </header>
        </div>
    );
};

//***********************************************************************************
// Get the Toolbar Navigation via a JSON string provided by the CMS
// the Toolbar NAV is the topmost nav in the header, giving the user social media
// Links, search bars, etc.
//
const ToolbarNavLeftTemplate = ({ children }) => {
    const toolbarNavLeftMenu = getCMSObject('toolbarLeftMenu', 'webMenu');

    return (
        <Navbar className='nav-header-left' expand='xl'>
            <Container>
                <Navbar id='basic-navbar-nav'>
                    <Menu menu_json={toolbarNavLeftMenu} className='text-center text-xl-left' />
                </Navbar>
                {children}
            </Container>
        </Navbar>
    );
};

const ToolbarNavLeft = connect(mapStateToProps, mapDispatchToProps)(ToolbarNavLeftTemplate);

//***********************************************************************************
// Get the Toolbar Navigation via a JSON string provided by the CMS
// the Toolbar NAV is the topmost nav in the header, giving the user social media
// Links, search bars, etc.
//
const ToolbarNavRightTemplate = ({ children }) => {
    const toolbarNavRightMenu = getCMSObject('toolbarRightMenu', 'webMenu');

    return (
        <Navbar className='nav-header-right' expand='xl'>
            <Container>
                <Navbar id='basic-navbar-nav'>
                    <Menu menu_json={toolbarNavRightMenu} className='text-center text-xl-right' />
                </Navbar>
                {children}
            </Container>
        </Navbar>
    );
};

const ToolbarNavRight = connect(mapStateToProps, mapDispatchToProps)(ToolbarNavRightTemplate);

const LotteryLogoTemplate = ({ cmsSourceFirebase }) => {
    return (
        <div className='header-lottery-logo-container'>
            <CMSContent
                localStorageObject='webContent'
                contentPath='data.sitewideSettings.headerLogo.image'
                className='img-fluid'
                cmsSourceFirebase={cmsSourceFirebase}
            />
        </div>
    );
};
const LotteryLogo = connect(mapStateToProps, mapDispatchToProps)(LotteryLogoTemplate);

//***********************************************************************************
// Get the Toolbar Navigation via a JSON string provided by the CMS
// the Toolbar NAV is the topmost nav in the header, giving the user social media
// Links, search bars, etc.
//
const LotteryNavTemplate = ({ children }) => {
    const lotteryMenu = getCMSObject('lotteryMenu', 'webMenu');
    return (
        <Navbar className='nav-lottery' collapseOnSelect expand='md'>
            <Container>
                <Navbar.Brand>
                    <LotteryLogo />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls='responsive-navbar-nav' />
                <Navbar.Collapse className='ml-auto'>
                    <Menu menu_json={lotteryMenu} className='text-center text-xl-right' />
                </Navbar.Collapse>
                {children}
            </Container>
        </Navbar>
    );
};

const LotteryNav = connect(mapStateToProps, mapDispatchToProps)(LotteryNavTemplate);

//***********************************************************************************
// Get the Primary Navigation via a JSON string provided by the CMS
// the Primary Navigation is a series of drop-downs for MO.
//
const PrimaryNavTemplate = ({ children }) => {
    const localStoragePrimaryMenu = getCMSObject('primaryMenu', 'webMenu');

    return (
        <Navbar className='nav-primary' expand='xl'>
            <Navbar id='basic-navbar-nav'>
                <Menu menu_json={localStoragePrimaryMenu} />
            </Navbar>
            {children}
        </Navbar>
    );
};
const PrimaryNav = connect(mapStateToProps, mapDispatchToProps)(PrimaryNavTemplate);

const MobileNavTemplate = () => {
    const toolbarNavLeftMenu = getCMSObject('toolbarLeftMenu', 'webMenu');
    const toolbarNavRightMenu = getCMSObject('toolbarRightMenu', 'webMenu');
    const lotteryMenu = getCMSObject('lotteryMenu', 'webMenu');
    const primaryMenu = getCMSObject('primaryMenu', 'webMenu');

    return (
        <div className='mobile-nav'>
            <Navbar className='d-flex d-md-none mobile-nav-row' expand='xs'>
                <Navbar.Brand>
                    <LotteryLogo />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls='responsive-navbar-nav'>
                    <FontAwesomeIcon icon='fa-solid fa-bars' />
                    <FontAwesomeIcon icon='fa-solid fa-xmark' />
                </Navbar.Toggle>
                <Navbar.Collapse className='ml-auto'>
                    <Menu menu_json={toolbarNavLeftMenu} className='toolbar-left-menu' />
                    <Menu menu_json={lotteryMenu} className='lottery-menu' />
                    <Menu menu_json={primaryMenu} className='primary-menu' />
                    <Menu menu_json={toolbarNavRightMenu} className='toolbar-right-menu' />
                </Navbar.Collapse>
            </Navbar>
        </div>
    );
};

const MobileNav = connect(mapStateToProps, mapDispatchToProps)(MobileNavTemplate);

export { Header, PrimaryNav, MobileNav };
