import React from 'react';
import { connect } from 'react-redux';
import { BoxedContent, CMSContent, hasCMSContent, getCMSObject, DisplayContent } from 'sg-ui-components';

import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';
import AcceptTermsOfUse from '../Components/AcceptTermsOfUse';

const TermsPage = ({ user, config, cmsSourceFirebase, actions, match }) => {
    const telescriptTermsOfUse = getCMSObject('data.components.teleScripts.termsOfUse.jsonBlock');
    const termsContent = getCMSObject('data.legalAndPolicyItems.termsOfUse.contentHTML');

    return (
        <Layout {...{ user, config, cmsSourceFirebase, actions, match }}>
            <DisplayContent note='Terms of Use section' isVisible={hasCMSContent('data.legalAndPolicyItems.termsOfUse.contentHTML')}>
                <BoxedContent
                    note='Terms section'
                    hash='terms'
                    isActive={true}
                    hasCollapser={false}
                    isVisible={hasCMSContent('data.legalAndPolicyItems.termsOfUse.contentHeaderText')}
                    title={
                        <CMSContent
                            localStorageObject='webContent'
                            contentPath='data.legalAndPolicyItems.termsOfUse.contentHeaderText'
                            cmsSourceFirebase={cmsSourceFirebase}
                        />
                    }>
                    <AcceptTermsOfUse user={user} actions={actions} termsContent={termsContent} telescript={telescriptTermsOfUse} />
                </BoxedContent>
            </DisplayContent>
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(TermsPage);
