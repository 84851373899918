import React from 'react';
import { connect } from 'react-redux';
import { getCMSObject, NextDrawings, formatDateTBD, RecentDrawings, ErrorBoundary, TeleScript, DisplayContent } from 'sg-ui-components';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';
import WinnerLinkWrapper from '../Components/WinnerLinkWrapper';
import promotionConfig from '../promotionConfig';

const WinnersPage = ({ user, config, winner, cmsSourceFirebase, actions, match }) => {
    const path = match.path;

    const telescriptNextDrawings = getCMSObject('data.components.teleScripts.nextDrawings.jsonBlock');
    const telescriptRecentSecondChanceDrawings = getCMSObject('data.components.teleScripts.recentSecondChanceDrawings.jsonBlock');
    const telescriptRecentPFDDrawings = getCMSObject('data.components.teleScripts.recentPFDDrawings.jsonBlock');
    const isPointsForDrawingsEnabled = getCMSObject('data.features.pointsForDrawings', 'webConfig');

    return (
        <Layout {...{ user, config, cmsSourceFirebase, actions, path, match }}>
            <ErrorBoundary fallback={<TeleScript line={telescriptNextDrawings?.fallback} />} details={{ user, config }}>
                <NextDrawings
                    {...{ user, winner, cmsSourceFirebase, actions }}
                    formatDate={formatDateTBD?.dateWithDayOfTheWeek}
                    telescript={telescriptNextDrawings ?? ''}
                    options={{
                        variant: 'theme-primary',
                        limitOnePerSweepstake: promotionConfig?.nextDrawingsLimitOnePerSweepstake ?? true,
                        maxElement: promotionConfig?.nextDrawingsMaxElement ?? 5,
                    }}
                />
            </ErrorBoundary>
            <ErrorBoundary fallback={<TeleScript line={telescriptRecentSecondChanceDrawings?.fallback} />} details={{ user, config }}>
                <RecentDrawings
                    winner={winner}
                    actions={actions}
                    entryType={isPointsForDrawingsEnabled ? 'promotion' : undefined}
                    telescript={telescriptRecentSecondChanceDrawings}
                    WinnerLink={WinnerLinkWrapper}
                    options={{
                        variant: 'theme-primary',
                        hasCollapser: true,
                        hash: 'recent-drawings',
                        pageSize: promotionConfig?.components?.recentDrawings?.defaultPageSize ?? 10,
                    }}
                />
            </ErrorBoundary>
            <DisplayContent isVisible={isPointsForDrawingsEnabled}>
                <ErrorBoundary fallback={<TeleScript line={telescriptRecentPFDDrawings?.fallback} />} details={{ user, config }}>
                    <RecentDrawings
                        winner={winner}
                        actions={actions}
                        entryType={'purchase'}
                        telescript={telescriptRecentPFDDrawings}
                        WinnerLink={WinnerLinkWrapper}
                        createDetailsLink={(sweepsId) => `/points-for-drawing-details/${sweepsId}`}
                        options={{
                            isVisible: promotionConfig?.components?.recentDrawings?.isVisible ?? true,
                            variant: 'theme-primary',
                            hasCollapser: true,
                            hash: 'recent-drawings',
                            pageSize: promotionConfig?.components?.recentDrawings?.defaultPageSize ?? 10,
                            linkOlderDrawings: telescriptRecentPFDDrawings?.linkOlderDrawingsUrl ?? `${config.config.store_uri}/drawing/landing.php`,
                        }}
                    />
                </ErrorBoundary>
            </DisplayContent>
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(WinnersPage);
