import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../Store';

import LoadingIndicator from '../Components/LoadingIndicator';
import promotionConfig from '../promotionConfig';

const Logout = ({ user, config, actions }) => {
    useEffect(() => {
        handleLogout();
    }, [config, user]);

    const handleLogout = async () => {
        const myHost = window.location.protocol + '//' + window.location.host;
        const logoutDestination = promotionConfig.getLogoutDestination(myHost, config?.config.logout_host_uri);

        await actions.userActions.logout();
        window.location.href = logoutDestination;
    };

    // Show loading state to avoid glitchy behavior of showing another screen that quickly switches to the loading page
    return <LoadingIndicator />;
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
