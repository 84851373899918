import React, { useEffect, useMemo, useState } from 'react';
import { PointsForDrawingsList, getCMSObject } from 'sg-ui-components';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PointsForDrawingsSection = ({ actions, config, pointsForDrawings, user }) => {
    const [pageLoaded, setPageLoaded] = useState(false);
    const pointsForDrawingTelescript = getCMSObject('data.components.teleScripts.pointsForDrawings.jsonBlock');

    useEffect(() => {
        async function fetchData() {
            await actions?.pointsForDrawingsActions?.getPointsForDrawings();
            setPageLoaded(true);
        }

        fetchData();
    }, [user.loggedIn, setPageLoaded]);

    const currentDrawings = useMemo(() => pointsForDrawings.pointsForDrawings?.filter((item) => item.category === 'current'), [pointsForDrawings]);

    return (
        <div>
            <PointsForDrawingsList
                config={config}
                action={actions}
                user={user}
                mode={'show-limited'}
                data={currentDrawings}
                pageLoaded={pageLoaded}
                telescript={pointsForDrawingTelescript}
                options={{
                    hasCollapser: true,
                    variant: 'theme-primary',
                    dateIcon: <FontAwesomeIcon className='pfd-date-icon' icon='far fa-clock' size={18} />,
                    pointIcon: <FontAwesomeIcon className='pfd-point-icon' icon='fa-solid fa-wallet' size={16} />,
                    entryIcon: <FontAwesomeIcon className='pfd-entry-icon' icon='fa-solid fa-ticket' size={18} />,
                    exclamationDateIcon: <FontAwesomeIcon icon='fa-solid fa-triangle-exclamation' className='pfd-date-icon' size={18} />,
                }}
                placeholderImageLink={pointsForDrawingTelescript?.placeholderImageLink}
            />
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(PointsForDrawingsSection);
