import React from 'react';
import { Breadcrumbs, IfNotMobile } from 'sg-ui-components';

// Specifically using custom version rather than sg-ui-components version since styling is unique
const TitleBreadCrumb = ({ pageData }) => {
    if (!pageData?.breadcrumb || !pageData?.pageHeader) {
        return null;
    }
    return (
        <div className='title-breadcrumb'>
            <div className='breadcrumb-arrow'>
                {pageData?.breadcrumb && (
                    <IfNotMobile>
                        <Breadcrumbs data={pageData.breadcrumb} />
                    </IfNotMobile>
                )}

                {pageData?.pageHeader && <h1 className='section-title'>{pageData.pageHeader}</h1>}
            </div>
        </div>
    );
};

export default TitleBreadCrumb;
