//TODO: refactor with displaycontent

import React from 'react';
import { connect } from 'react-redux';
import { BoxedContent, CMSContent, hasCMSContent } from 'sg-ui-components';

import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';

const FaqPage = ({ user, config, cmsSourceFirebase, actions, match }) => {
    return (
        <Layout {...{ user, config, cmsSourceFirebase, actions, match }}>
            {hasCMSContent('data.helpAndFaqs.faqs.contentHTML') && (
                <React.Fragment key='FAQ section'>
                    <BoxedContent
                        note='FAQ section'
                        hash='faq'
                        isActive={true}
                        hasCollapser={false}
                        isVisible={hasCMSContent('data.helpAndFaqs.faqs.contentHeaderText')}
                        title={
                            <CMSContent
                                localStorageObject='webContent'
                                contentPath='data.helpAndFaqs.faqs.contentHeaderText'
                                cmsSourceFirebase={cmsSourceFirebase}
                            />
                        }>
                        <CMSContent localStorageObject='webContent' contentPath='data.helpAndFaqs.faqs.contentHTML' cmsSourceFirebase={cmsSourceFirebase} />
                    </BoxedContent>
                </React.Fragment>
            )}
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(FaqPage);
