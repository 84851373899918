import React from 'react';
import { connect } from 'react-redux';
import { PointsForDrawingsDetailsView, getCMSObject } from 'sg-ui-components';

import { useParams } from 'react-router';
import Layout from '../Components/Layout';
import { mapDispatchToProps, mapStateToProps } from '../Store';
import siteConfig from '../promotionConfig';

const PointsForDrawingsDetailsPage = ({ user, config, sweep, cmsSourceFirebase, actions, pointsForDrawings, winner, match }) => {
    const params = useParams();
    const sweepId = params?.sweepId;
    const path = match?.path;
    const promotionName = pointsForDrawings.pointsForDrawings.filter((val) => String(val.id) === String(sweepId))?.[0]?.name;
    const telescriptPointsForDrawingsDetails = getCMSObject('data.components.teleScripts.pointsForDrawingsDetails.jsonBlock');

    return (
        <Layout
            {...{
                user,
                sweep,
                config,
                cmsSourceFirebase,
                actions,
                match,
                configReplacements: [
                    {
                        find: '{{PROMOTION_NAME}}',
                        replace: promotionName || 'Points for Drawings',
                    },
                ],
            }}>
            <PointsForDrawingsDetailsView
                user={user}
                actions={actions}
                pointsForDrawings={pointsForDrawings}
                sweepId={sweepId}
                options={{
                    loginUrl: `${siteConfig?.components?.pointsForDrawingsDetailsView?.loginUrl ?? '/login'}?redirect=${path?.replace(':sweepId', sweepId)}`,
                    registerUrl: siteConfig?.components?.pointsForDrawingsDetailsView?.registerUrl ?? '/register',
                    defaultNumEntries: siteConfig?.components?.pointsForDrawingsDetailsView?.defaultNumEntries ?? 1,
                }}
                telescript={telescriptPointsForDrawingsDetails}
                winner={winner}
                showWinner={true}
                formContainerClassName={'points-for-drawings-form-container'}
            />
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(PointsForDrawingsDetailsPage);
