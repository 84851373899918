import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import { validatePassword } from '../../validationRules';
import { errorMessage } from '../errorMessages';
import { ContentBox, ContentBoxHead, ContentBoxBody, isBrowser, PasswordField, getCMSObject, TeleScript, CMSContent } from 'sg-ui-components';
import { PasswordStatusForm } from '../ui-components/PasswordStatusForm';
import { CheckPassword } from '../ui-components/CheckPassword';

const RecoverPassword = ({ loading, user, actions, cmsSourceFirebase }) => {
    //* Retrieve ID from url params
    const queryString = isBrowser() ? window.location.search : '';
    const urlParams = new URLSearchParams(queryString);
    const queryParams = {
        passcode: urlParams.get('passcode'),
        mode: urlParams.get('mode'),
    };

    const initialStepFields = {
        password: '',
        confirm: '',
    };

    const schema = yup.object().shape({
        password: validatePassword,
        confirm: yup
            .string()
            .required('Password Confirmation is required')
            .oneOf([yup.ref('password'), null], 'Passwords must match'),
    });

    const [stepFields, setStepFields] = useState(initialStepFields);
    const [error, setError] = useState('');
    const loginTelescript = getCMSObject('data.components.teleScripts.login.jsonBlock');
    const [passwordStatus, setPasswordStatus] = useState('initial');
    const [confirmPasswordStatus, setConfirmPasswordStatus] = useState('initial');
    const updateLoginTelescript = getCMSObject('data.components.teleScripts.updateLoginInfo.jsonBlock');

    useEffect(() => {
        if (user.errors) {
            setError(user.errors);
        }
    }, [user]);

    // if the form field changes, we will change the value in the store and
    const handleChange = async (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value.trimStart();
        let name = target.name;

        setStepFields({
            ...stepFields,
            [name]: value,
        });
        //* Password inner change
        if (name === 'password') {
            let passwordStatus = await CheckPassword(value);
            if (passwordStatus === 'valid') {
                setPasswordStatus(passwordStatus);
                if (value !== stepFields.confirm) {
                    setConfirmPasswordStatus('Passwords do not match.');
                } else {
                    setConfirmPasswordStatus(passwordStatus);
                }
            } else {
                setPasswordStatus('Your password does not match the criteria.');
            }
        } else if (name === 'confirm') {
            let passwordStatus = await CheckPassword(value);
            if (value !== stepFields.password) {
                setConfirmPasswordStatus('Passwords do not match.');
            } else if (passwordStatus !== 'valid') {
                setConfirmPasswordStatus('Your password does not match the criteria.');
            } else {
                setConfirmPasswordStatus(passwordStatus);
            }
        }
    };

    const handleSubmit = async (e) => {
        // TODO: Replace with Formik so that we don't need to manually write validation and error displaying logic
        e.preventDefault();

        let valid = await schema.isValid(stepFields);

        if (valid) {
            await actions.loadingActions.setLoading({ action: 'recoverPassword' });

            await actions.userActions.updateSection({ section: 'recover-password', status: 'initial' });

            const payloadData = {
                password: stepFields.password,
                confirm: stepFields.confirm,
                passcode: queryParams.passcode,
            };

            await actions.userActions.recoverPassword(payloadData);
        }
    };

    //* Passcode validation
    if (!queryParams.passcode) {
        window.location.href = '/forgot-password';
        return null;
    }

    //* reset success
    if (user.updateSection.section === 'recover-password' && user.updateSection.status === 'success') {
        return (
            <div className='login-container'>
                <div className='my-5' />
                <ContentBox variant='theme-blue'>
                    <ContentBoxBody>
                        <div className='form-step'>
                            <div className='inner-step text-center'>
                                <div>
                                    <CMSContent
                                        localStorageObject='webContent'
                                        contentPath='data.sitewideSettings.mainLogo.image'
                                        className='img-fluid'
                                        cmsSourceFirebase={cmsSourceFirebase}
                                    />
                                </div>
                                <p>Congratulations, your password has been updated.</p>
                                <p>
                                    <a href='/login' className='continue-to-login-btn'>
                                        Continue to Login
                                    </a>
                                </p>
                            </div>
                        </div>
                    </ContentBoxBody>
                </ContentBox>
            </div>
        );
    }

    return (
        <div className='login-container'>
            <div className='my-5' />
            <ContentBox variant='theme-blue'>
                <ContentBoxHead>
                    <TeleScript line={loginTelescript?.updatePasswordTitle}>RESET MY PASSWORD</TeleScript>
                </ContentBoxHead>
                <ContentBoxBody>
                    <div className='form-step'>
                        <div className='inner-step'>
                            {error ? (
                                <div className='alert alert-danger text-center' role='alert'>
                                    <p dangerouslySetInnerHTML={{ __html: errorMessage(error) }} />
                                </div>
                            ) : null}

                            {user.updateSection.section === 'recover-password' && user.updateSection.status === 'error' && (
                                <div className='alert alert-danger text-center' role='alert'>
                                    {errorMessage(user.updateSection.code)}
                                </div>
                            )}

                            <div className='form-group password-field'>
                                <label htmlFor='password'>New Password</label>
                                <TeleScript line={updateLoginTelescript.passwordRequirements}>
                                    <p className='small'>
                                        Your Password must be at least 10 characters long and include - 1 uppercase letter, 1 lowercase letter, 1 number, and 1
                                        special character.
                                    </p>
                                </TeleScript>
                                <PasswordField
                                    id='password'
                                    name='password'
                                    autoComplete='off'
                                    placeholder='New Password'
                                    value={stepFields.password}
                                    handleChange={handleChange}
                                />
                                {PasswordStatusForm(passwordStatus)}
                            </div>

                            <div className='form-group password-field'>
                                <label htmlFor='confirmPassword'>Confirm New Password</label>
                                <PasswordField
                                    name='confirm'
                                    placeholder='Password'
                                    value={stepFields.confirm}
                                    autoComplete='off'
                                    handleChange={handleChange}
                                />
                                {PasswordStatusForm(confirmPasswordStatus)}
                                <p className='small'>Please retype your password.</p>
                            </div>

                            <div className='row justify-content-center'>
                                <button type='button' className='recover-password-submit' onClick={handleSubmit} disabled={loading.actions['recoverPassword']}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </ContentBoxBody>
            </ContentBox>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(RecoverPassword);
