import React from 'react';
import { connect } from 'react-redux';
import { BoxedContent, CMSContent, hasCMSContent, FeedbackForm, getCMSObject } from 'sg-ui-components';

import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';

const Feedback = ({ user, config, cmsSourceFirebase, actions, match, feedbackCategories, promotion, loading, feedback }) => {
    const feedbackFormTelescript = getCMSObject('data.components.teleScripts.feedback.jsonBlock');

    return (
        <Layout {...{ user, config, cmsSourceFirebase, actions, match }}>
            {hasCMSContent('data.helpAndFaqs.helpIntro.contentHTML') && (
                <React.Fragment key='Help Intro section'>
                    <div className='row about-section'>
                        <div id='about' className='my-3 col-md-3'>
                            <CMSContent
                                localStorageObject='webContent'
                                contentPath='data.helpAndFaqs.helpIntro.image'
                                className='w-100 rounded-image'
                                cmsSourceFirebase={cmsSourceFirebase}
                            />
                        </div>
                        <div className='col d-flex align-items-center players-club-blurb'>
                            <CMSContent
                                localStorageObject='webContent'
                                contentPath='data.helpAndFaqs.helpIntro.contentHTML'
                                cmsSourceFirebase={cmsSourceFirebase}
                            />
                        </div>
                    </div>
                </React.Fragment>
            )}

            {hasCMSContent('data.helpAndFaqs.howItWorks.contentHTML') && (
                <React.Fragment key='How It Works section'>
                    <BoxedContent
                        note='How It Works section'
                        hash='how-it-works'
                        isActive={false}
                        isVisible={hasCMSContent('data.helpAndFaqs.howItWorks.contentHeaderText')}
                        title={
                            <CMSContent
                                localStorageObject='webContent'
                                contentPath='data.helpAndFaqs.howItWorks.contentHeaderText'
                                cmsSourceFirebase={cmsSourceFirebase}
                            />
                        }>
                        <CMSContent
                            localStorageObject='webContent'
                            contentPath='data.helpAndFaqs.howItWorks.contentHTML'
                            cmsSourceFirebase={cmsSourceFirebase}
                        />
                    </BoxedContent>
                </React.Fragment>
            )}

            {hasCMSContent('data.helpAndFaqs.faqs.contentHTML') && (
                <React.Fragment key='FAQ section'>
                    <BoxedContent
                        note='FAQ section'
                        hash='faq'
                        isActive={false}
                        isVisible={hasCMSContent('data.helpAndFaqs.faqs.contentHeaderText')}
                        title={
                            <CMSContent
                                localStorageObject='webContent'
                                contentPath='data.helpAndFaqs.faqs.contentHeaderText'
                                cmsSourceFirebase={cmsSourceFirebase}
                            />
                        }>
                        <CMSContent localStorageObject='webContent' contentPath='data.helpAndFaqs.faqs.contentHTML' cmsSourceFirebase={cmsSourceFirebase} />
                    </BoxedContent>
                </React.Fragment>
            )}

            {hasCMSContent('data.helpAndFaqs.howToClaim.contentHTML') && (
                <React.Fragment key='How To Claim section'>
                    <BoxedContent
                        note='How To Claim section'
                        hash='how-to-claim'
                        isActive={false}
                        isVisible={hasCMSContent('data.helpAndFaqs.howToClaim.contentHeaderText')}
                        title={
                            <CMSContent
                                localStorageObject='webContent'
                                contentPath='data.helpAndFaqs.howToClaim.contentHeaderText'
                                cmsSourceFirebase={cmsSourceFirebase}
                            />
                        }>
                        <CMSContent
                            localStorageObject='webContent'
                            contentPath='data.helpAndFaqs.howToClaim.contentHTML'
                            cmsSourceFirebase={cmsSourceFirebase}
                        />
                    </BoxedContent>
                </React.Fragment>
            )}

            <FeedbackForm
                user={user}
                actions={actions}
                feedbackCategories={feedbackCategories}
                promotion={promotion}
                loading={loading}
                cmsSourceFirebase={cmsSourceFirebase}
                feedback={feedback}
                telescript={feedbackFormTelescript}
            />

            {hasCMSContent('data.helpAndFaqs.rules.contentHTML') && (
                <React.Fragment key='Rules section'>
                    <BoxedContent
                        note='Rules section'
                        hash='rules'
                        isActive={false}
                        isVisible={hasCMSContent('data.helpAndFaqs.rules.contentHeaderText')}
                        title={
                            <CMSContent
                                localStorageObject='webContent'
                                contentPath='data.helpAndFaqs.rules.contentHeaderText'
                                cmsSourceFirebase={cmsSourceFirebase}
                            />
                        }>
                        <CMSContent localStorageObject='webContent' contentPath='data.helpAndFaqs.rules.contentHTML' cmsSourceFirebase={cmsSourceFirebase} />
                    </BoxedContent>
                </React.Fragment>
            )}

            {hasCMSContent('data.legalAndPolicyItems.termsOfUse.contentHTML') && (
                <React.Fragment key='Terms of Use section'>
                    <BoxedContent
                        note='Terms section'
                        hash='terms'
                        isActive={false}
                        isVisible={hasCMSContent('data.legalAndPolicyItems.termsOfUse.contentHeaderText')}
                        title={
                            <CMSContent
                                localStorageObject='webContent'
                                contentPath='data.legalAndPolicyItems.termsOfUse.contentHeaderText'
                                cmsSourceFirebase={cmsSourceFirebase}
                            />
                        }>
                        <CMSContent
                            localStorageObject='webContent'
                            contentPath='data.legalAndPolicyItems.termsOfUse.contentHTML'
                            cmsSourceFirebase={cmsSourceFirebase}
                        />

                        <React.Fragment key='Accept Terms of Use section'>
                            {user?.player?.actions?.includes('tos') ? (
                                <div className='d-flex justify-content-center'>
                                    <input
                                        type='submit'
                                        name='submit'
                                        value='Accept Terms of Use'
                                        className='accept-tos-btn'
                                        onClick={() => actions.userActions.actionComplete({ action_name: 'tos' })}
                                    />
                                </div>
                            ) : null}
                        </React.Fragment>
                    </BoxedContent>
                </React.Fragment>
            )}

            {hasCMSContent('data.helpAndFaqs.privacyPolicy.contentHTML') && (
                <React.Fragment key='Privacy Policy section'>
                    <BoxedContent
                        note='Privacy section'
                        hash='privacy'
                        isActive={false}
                        isVisible={hasCMSContent('data.legalAndPolicyItems.privacyPolicy.contentHeaderText')}
                        title={
                            <CMSContent
                                localStorageObject='webContent'
                                contentPath='data.legalAndPolicyItems.privacyPolicy.contentHeaderText'
                                cmsSourceFirebase={cmsSourceFirebase}
                            />
                        }>
                        <CMSContent
                            localStorageObject='webContent'
                            contentPath='data.legalAndPolicyItems.privacyPolicy.contentHTML'
                            cmsSourceFirebase={cmsSourceFirebase}
                        />
                    </BoxedContent>
                </React.Fragment>
            )}
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Feedback);
