import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';

import { RafPage } from '../Components/ReferAFriend';
import { RafForm } from '../Components/ReferAFriend/RafForm';
import { ShareRafCode } from '../Components/ReferAFriend/RafForm';
import { RafLink } from '../Components/ReferAFriend/RafForm';

const ReferAFriendPage = ({ user, config, cmsSourceFirebase, actions, match }) => {
    return (
        <Layout {...{ user, config, cmsSourceFirebase, actions, match }}>
            <RafPage>
                <RafForm>
                    {/* <RafErrorCard/>
                    <RafCode /> */}
                    <RafLink />
                    <ShareRafCode />
                </RafForm>
            </RafPage>
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ReferAFriendPage);
