import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { render } from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ScrollToTop, ErrorBoundary, TeleScript, getCMSObject } from 'sg-ui-components';

import { createStore } from './Store';

import HomePage from './pages/home';
import Feedback from './pages/feedback';
import Promotions from './pages/promotions';
import SecondChancePromotion from './pages/secondChancePromotion';
import MyActivityPage from './pages/myActivity';

import PostClaimPage from './pages/postClaim';
import WinnersPage from './pages/winners';
import PageNotFound from './pages/404';
import Logout from './pages/logout';

import EnterPage from './pages/enter';

import PromotionDetailsPage from './pages/promotionDetails';
import ReferAFriendPage from './pages/referAFriend';
import SurveyPage from './pages/survey';
import MyProfilePage from './pages/myProfile';
import Register from './pages/register';
import ForgotPasswordPage from './pages/forgotPassword';
import RecoverPasswordPage from './pages/recoverPassword';
import UpdatePasswordPage from './pages/updatePassword';
import PasswordExpiringPage from './pages/passwordExpiring';
import PasswordExpiredPage from './pages/passwordExpired';
import AccountConfirmPage from './pages/accountConfirm';
import AwaitAccountConfirmationPage from './pages/awaitAccountConfirmation';
import RegisterVerificationErrorPage from './pages/registerVerificationError';
import LoginPage from './pages/login';
import Drawings from './pages/drawings';
import pointsForDrawingsDetailsPage from './pages/pointsForDrawingsDetailsPage';

import FaqPage from './pages/faq';
import RulesPage from './pages/rules';
import TermsPage from './pages/terms';
import PrivacyPage from './pages/privacy';
import ContactUsPage from './pages/contactUs';

import './styling/global.scss';

const app = document.getElementById('root');

const errorBoundaryTelescript = getCMSObject('data.messages.pageErrorMessages.jsonBlock');

render(
    <Provider store={createStore()}>
        <ErrorBoundary fallback={<TeleScript line={errorBoundaryTelescript?.rootFallback} />} details={{}}>
            <Router basename='/'>
                <ScrollToTop />
                <Suspense fallback={<div>Loading...</div>}>
                    <Switch>
                        <Route exact path='/' component={HomePage} />
                        <Route path='/home' component={HomePage} />
                        <Route path='/winners' component={WinnersPage} />
                        <Route path='/promotions' component={Promotions} />
                        <Route path='/drawings' component={Drawings} />
                        <Route path='/points-for-drawing-details/:sweepId' component={pointsForDrawingsDetailsPage} />
                        <Route path='/second-chance-promotion' component={SecondChancePromotion} />
                        <Route path='/feedback' component={Feedback} />

                        <Route path='/post-claim' component={PostClaimPage} />
                        <Route path='/my-activity' component={MyActivityPage} />

                        <Route path='/login' component={LoginPage} />

                        <Route path='/logout' component={Logout} />

                        <Route path='/enter' component={EnterPage} />
                        <Route path='/register' component={Register} />
                        <Route path='/register-verification-error' component={RegisterVerificationErrorPage} />

                        <Route path='/forgot-password' component={ForgotPasswordPage} />
                        <Route path='/recover-password' component={RecoverPasswordPage} />
                        <Route path='/password-expiring' component={PasswordExpiringPage} />
                        <Route path='/password-expired' component={PasswordExpiredPage} />
                        <Route path='/update-password' component={UpdatePasswordPage} />

                        <Route path='/account-confirm' component={AccountConfirmPage} />
                        <Route path='/await-account-confirmation' component={AwaitAccountConfirmationPage} />
                        <Route path='/promotion' component={PromotionDetailsPage} />
                        <Route path='/refer-a-friend' component={ReferAFriendPage} />
                        <Route path='/survey' component={SurveyPage} />

                        <Route path='/my-profile' component={MyProfilePage} />

                        <Route path='/faq' component={FaqPage} />
                        <Route path='/rules' component={RulesPage} />
                        <Route path='/terms' component={TermsPage} />
                        <Route path='/privacy' component={PrivacyPage} />
                        <Route path='/contact-us' component={ContactUsPage} />

                        <Route path='*' component={PageNotFound} />

                        {/*
                    //? Disabled functionality
                    <Route path='/login-pta' component={LoginPTA} />


                    <Route path='/lottery-promotion' component={LotteryPromotion} />



                    <Route path='/terms' component={Terms} />
                    <Route path='/privacy' component={Privacy} />

                    <Route path='/login-template' component={Login} />

                    */}
                    </Switch>
                </Suspense>
            </Router>
        </ErrorBoundary>
    </Provider>,
    app
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
