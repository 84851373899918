import React from 'react';

import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import { Container } from 'react-bootstrap';
import { ContentBox, ContentBoxBody, ContentBoxHead, getCMSObject, TeleScript } from 'sg-ui-components';

// Set up the RAF Page, which is only accessible to logged in users.
const RafPageTemplate = ({ children }) => {
    const rafTelescript = getCMSObject('data.components.teleScripts.referAFriend.jsonBlock');

    return (
        <Container className='raf-container theme-form'>
            <div className='row justify-content-center'>
                <div className='col-12 col-lg-10'>
                    <ContentBox variant='theme-primary'>
                        <ContentBoxHead>
                            <TeleScript line={rafTelescript.title}>Refer A Friend</TeleScript>
                        </ContentBoxHead>
                        <ContentBoxBody>{children}</ContentBoxBody>
                    </ContentBox>
                </div>
            </div>
        </Container>
    );
};

const RafPage = connect(mapStateToProps, mapDispatchToProps)(RafPageTemplate);

export { RafPage };
