import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';

import { ContentBox, ContentBoxHead, ContentBoxBody } from 'sg-ui-components';
import { Link } from 'react-router-dom';

const PasswordExpired = () => {
    return (
        <div className='login-container'>
            <div className='my-5' />
            <ContentBox variant='theme-blue'>
                <ContentBoxHead>PASSWORD EXPIRED</ContentBoxHead>
                <ContentBoxBody>
                    <div className='text-center'>
                        <p>Your password has expired. Please update it now to continue using all of the features of 2nd Chance</p>
                        <Link to='/my-profile' className='reset-pw-submit'>
                            Update Now
                        </Link>
                    </div>
                </ContentBoxBody>
            </ContentBox>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordExpired);
